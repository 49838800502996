import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import AxiosPlugin from "./plugins/axios";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VeeValidatePlugin from "./plugins/vee-validate";
import "./plugins/snotify";
import MainLayout from "./layouts/MainLayout";
import AlertOffline from "@/components/offline/AlertOffline.vue";
import PromptPWA from "@/components/PromptPWA.vue";

import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: { id: "G-Y3BD054S4L" },
  },
  router
);

Vue.config.productionTip = false;

Vue.use(AxiosPlugin);
Vue.use(VeeValidatePlugin);

Vue.component("alert-offline", AlertOffline);
Vue.component("prompt-pwa", PromptPWA);

// Layouts
Vue.component("main-layout", MainLayout);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
